<template>
  <Row borderStyle="rc-border-thin">
    <Column>

      <Box>
        <ErrorRow :error="error" />
      </Box>

      <Box>
        <Row :showBorder="true">

          <Column :width="2" />
          <Column :width="11" />

          <Column :width="2">
            <Button :disabled="isLoadingDetails" v-on:click="refreshStats">Refresh</Button>
          </Column>

        </Row>
      </Box>

      <Box :showBorder="true">
        <TitleRowV2> Insights for Today </TitleRowV2>

        <Row>

          <Column v-if="hasCheckIns" :width="-1"> <!-- Check-Ins -->
            <Box :showBorder="true">

              <TitleRowV2 :subtitle="true"> Check-Ins </TitleRowV2>
              <StringValueRow class="clickable" label="All" :value="Strings.toString(stats.checkins())"
                v-on:click="showCheckIns('today')" />

              <div class="rc-row">
                <div class="rc-box-col-8 rc-text-label">User</div>
                <div class="rc-box-col-5 rc-text-label">Time</div>
                <div class="rc-box-col-2 rc-text-label rc-text-centre">Status</div>
              </div>
              <div class="rc-row " style="overflow-y: scroll; height:90px;">

                <div v-if="checkInList.length > 0" class="rc-box-col-15">
                  <div class="rc-container">
                    <Row :class="Strings.isEven(index) ? 'rowEven' : 'rowOdd'" class="clickable"
                      v-for="(checkIn, index) in checkInList" :key="index + 'cil'"
                      v-on:click="showCheckInDetails(checkIn)">
                      <div class="rc-box-col-8 rc-text-value rc-text-small">
                        {{ checkIn.employee().find().user().find().fullName() }}
                      </div>
                      <div class="rc-box-col-5 rc-text-value rc-text-small">
                        {{ checkIn.createdDateV2().displayAs_HH_MM_A() }}
                      </div>
                      <div :class="defectTypeColour(checkIn.result())"
                        class="rc-box-col-2 rc-text-value rc-text-small rc-text-centre">
                        {{ checkIn.resultDisplay() }}
                      </div>
                    </Row>
                  </div>
                </div> <!-- box-col-15 -->
                <div v-else class="rc-box-col-15">
                  <Box v-if="isLoadingCheckIns">
                    <LoadingRow />
                  </Box>
                  <Box v-else>
                    <ValueRow> No Check-Ins Today </ValueRow>
                  </Box>
                </div>
              </div> <!-- container -->

            </Box>
          </Column> <!-- Check-Ins -->

          <Column v-if="hasTimesheets" :width="-1"> <!-- Hours -->
            <Box :showBorder="true">

              <TitleRowV2 :subtitle="true"> Hours of Service </TitleRowV2>
              <Row>
                <Column :width="15">
                  <Value class="clickable" v-on:click="showHours('today')">All {{ Strings.toString(stats.timesheets())
                    }}</Value>
                </Column>
              </Row>
              <div class="rc-row">
                <div class="rc-box-col-8 rc-text-label">User</div>
                <div class="rc-box-col-4 rc-text-label">Time</div>
                <div class="rc-box-col-3 rc-text-label rc-text-centre">Status</div>
              </div>
              <div class="rc-row " style="overflow-y: scroll; height:90px;">
                <div class="rc-box-col-15" v-if="timesheetList.length > 0">
                  <div class="rc-container">
                    <Row :class="Strings.isEven(index) ? 'rowEven' : 'rowOdd'" class="clickable"
                      v-for="(timesheet, index) in timesheetList" :key="index + 'tsl'">
                      <div class="rc-box-col-8 rc-text-value rc-text-small " v-on:click="showHourDetails(timesheet)">
                        {{ timesheet.employee().find().user().find().fullName() }}
                      </div>
                      <div class="rc-box-col-4 rc-text-value rc-text-small " v-on:click="showHourDetails(timesheet)">
                        {{ timesheet.createdDateV2().displayAs_HH_MM_A() }}
                      </div>
                      <div class="rc-box-col-3 rc-text-value rc-text-small rc-text-centre"
                        :class="defectTypeColour(timesheet.status())" v-on:click="showHourDetails(timesheet)">
                        {{ timesheet.statusDisplay() }}
                      </div>
                    </Row>
                  </div>
                </div> <!-- box-col-15 -->
                <div v-else class="rc-box-col-15">
                  <Box v-if="isLoadingTimesheets">
                    <LoadingRow />
                  </Box>
                  <Box v-else>
                    <ValueRow> No Hours of Service Today </ValueRow>
                  </Box>
                </div>
              </div> <!-- container -->
            </Box>
          </Column> <!-- Hours -->

          <Column v-if="hasInspections" :width="-1"> <!-- Inspections -->
            <Box :showBorder="true">

              <TitleRowV2 :subtitle="true"> Inspections </TitleRowV2>
              <Row>
                <Column :width="2">
                  <Value class="clickable" v-on:click="showInspections('today')">All {{ Strings.toString(stats.inspections()) }}</Value>
                </Column>
                <Column :width="11">
                </Column>
                <Column :width="1">
                  <Button v-if="canPreviousPage(inspectionPage)" :disabled="isLoadingInspections"
                    v-on:click="previousPage('inspections')">&lt;</Button>
                </Column>
                <Column :width="1">
                  <Button v-if="canNextPage(inspectionTotal, inspectionPage)" :disabled="isLoadingInspections"
                    v-on:click="nextPage('inspections')">&gt;</Button>
                </Column>
              </Row>
              <div class="rc-row">
                <div class="rc-box-col-5 rc-text-label">User</div>
                <div class="rc-box-col-3 rc-text-label">Time</div>
                <div class="rc-box-col-5 rc-text-label">Vehicle</div>
                <div class="rc-box-col-2 rc-text-label rc-text-centre">Status</div>
              </div>
              <div class="rc-row " style="overflow-y: scroll; height:90px;">
                <div class="rc-box-col-15" v-if="inspectionList.length > 0">
                  <HomeInspectionRow :inspectionData="inspectionMap.data" />
                </div> <!-- box-col-15 -->
                <div v-else class="rc-box-col-15">
                  <Box v-if="isLoadingInspections">
                    <LoadingRow />
                  </Box>
                  <Box v-else>
                    <ValueRow> No Inspections Today </ValueRow>
                  </Box>
                </div>
              </div> <!-- Row -->

            </Box>
          </Column> <!-- Inspections -->

        </Row>

      </Box>

      <SpacerRow />

      <Box :showBorder="true">
        <TitleRowV2> {{ insightsLabel() }} </TitleRowV2>
        <Row>

          <Column v-if="hasCheckIns" :width="-1">
            <Box :showBorder="true">
              <TitleRowV2 :subtitle="true"> Check-Ins </TitleRowV2>
              <div class="rc-row clickable" v-on:click="showCheckIns">
                <div class="rc-box-col-13 rc-text-label">All</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ statsMonthly.checkins() }}</div>
              </div>
              <SpacerRow />
              <SpacerRow />
              <SpacerRow />
              <StringValueRow v-if="isLoadingMonthly" value="Loading..." label="" />
              <SpacerRow v-else />
            </Box>
          </Column>
          <Column v-if="hasTimesheets" :width="-1">
            <div class="rc-container rc-background-dark-thin">
              <TitleRowV2 :subtitle="true"> Hours of Service </TitleRowV2>
              <div class="rc-row clickable" v-on:click="showHours">
                <div class="rc-box-col-13 rc-text-label">All</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ statsMonthly.timesheets() }}</div>
              </div>
              <div class="rc-row clickable " v-on:click="showHoursIncomplete">
                <div class="rc-box-col-13 rc-text-label">Incomplete</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ monthlyIncomplete }}</div>
              </div>
              <div class="rc-row clickable " v-on:click="showHoursInProgress">
                <div class="rc-box-col-13 rc-text-label">In Progress</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ stats.inProgress() }}</div>
              </div>
              <div v-if="hasUserCompliance" class="rc-row clickable " v-on:click="showHoursMissing">
                <div class="rc-box-col-13 rc-text-label">Missing</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ statsMonthly.missing() }}</div>
              </div>
              <div v-else class="rc-row ">
                <div class="rc-box-col-13 rc-text-label">Missing</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ statsMonthly.missing() }}</div>
              </div>
              <StringValueRow v-if="isLoadingMonthly || isLoadingDaily" value="Loading..." label="" />
              <SpacerRow v-else />
            </div>
          </Column>

          <Column v-if="hasInspections" :width="-1">
            <div class="rc-container rc-background-dark-thin">
              <TitleRowV2 :subtitle="true"> Inspections </TitleRowV2>
              <div class="rc-row clickable" v-on:click="showInspections('thisMonth')">
                <div class="rc-box-col-13 rc-text-label">All</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ statsMonthly.inspections() }}</div>
              </div>
              <div class="rc-row clickable " v-on:click="showInspectionsWithDefects()">
                <div class="rc-box-col-13 rc-text-label">Unfixed Defects</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">
                  {{ statsMonthly.reportedDefects() }}
                </div>
              </div>
              <div class="rc-row  clickable" v-on:click="showInspectionsWithMinorDefects()">
                <div class="rc-box-col-13 rc-text-label">Minors</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ statsMonthly.reportedMinors() }}</div>
              </div>
              <div class="rc-row  clickable" v-on:click="showInspectionsWithMajorDefects">
                <div class="rc-box-col-13 rc-text-label">Majors</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ statsMonthly.reportedMajors() }}</div>
              </div>

              <StringValueRow v-if="isLoadingMonthly" value="Loading..." label="" />
              <SpacerRow v-else />

            </div>
          </Column>

          <Column v-if="hasInspections" :width="-1">
            <div class="rc-container rc-background-dark-thin">
              <TitleRowV2 v-if="stats.trailers() > 0" :subtitle="true"> Vehicles and Trailers </TitleRowV2>
              <TitleRowV2 v-else :subtitle="true"> Vehicles </TitleRowV2>
              <div class="rc-row clickable" v-on:click="showVehiclesAll">
                <div class="rc-box-col-13 rc-text-label">All</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ stats.vehicles() + stats.trailers() }}</div>
              </div>
              <div class="rc-row clickable " v-on:click="showVehicles">
                <div class="rc-box-col-13 rc-text-label">Vehicles</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ stats.vehicles() }}</div>
              </div>

              <div class="rc-row clickable " v-on:click="showTrailers" v-if="stats.trailers() > 0">
                <div class="rc-box-col-13 rc-text-label">Trailers</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ stats.trailers() }}</div>
              </div>
              <SpacerRow v-else />
              <SpacerRow />
              <StringValueRow v-if="isLoadingDaily" value="Loading..." label="" />
              <SpacerRow v-else />

            </div>
          </Column>

          <Column :width="-1">
            <div class="rc-container rc-background-dark-thin">
              <TitleRowV2 :subtitle="true"> Users </TitleRowV2>
              <div class="rc-row clickable" v-on:click="showUsers('all')">
                <div class="rc-box-col-13 rc-text-label">All</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ stats.users() + stats.usersInvited() }}</div>
              </div>
              <div class="rc-row clickable" v-on:click="showUsers('active')">
                <div class="rc-box-col-13 rc-text-label">Active</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ stats.users() }}</div>
              </div>
              <div class="rc-row clickable " v-on:click="showUsersInvited()">
                <div class="rc-box-col-13 rc-text-label">Invited</div>
                <div class="rc-box-col-2 rc-text-centre rc-text-value">{{ stats.usersInvited() }}</div>
              </div>

              <SpacerRow />
              <StringValueRow v-if="isLoadingDaily" value="Loading..." label="" />
              <SpacerRow v-else />
            </div>
          </Column>


        </Row>
      </Box>

      <div class="rc-container">
        <SpacerRow />
      </div>

      <Box :showBorder="true" v-if="hasInspections">
        <TitleRowV2> Insights for Last 2 Years </TitleRowV2>
        <Row> <!-- row -->

          <Column :showBorder="true" :width="10">
            <Box>
              <TitleRowV2 :subtitle="true"> Inspection Results </TitleRowV2>
              <Row class="clickable" v-on:click="showInspectionsLast2Years">
                <Column class="rc-text-label" :width="13">
                  All Inspections
                </Column>
                <Column class="rc-text-centre rc-text-value clickable" :width="2" align="right" v-on:click="showInspectionsLast2Years">
                  {{ statsLast2Years.inspections() }}
                </Column>
              </Row>
              <Row>
                <Column>
                  <Box :showBorder="true">
                    <Row >
                      <Column :width="13" class="rc-text-label">
                        <div class="clickable " v-on:click="showInspectionsLast2YearsWithDefects">
                          Total Inspections with unrepaired Defects
                        </div>
                      </Column>
                      <Column :width="2" class="rc-text-centre rc-text-value">
                        <div class="clickable " v-on:click="showInspectionsLast2YearsWithDefects">
                          {{ statsLast2Years.reportedDefects() }}
                        </div>
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <Box :showBorder="true">
                          <Row>
                            <div class="rc-box-col-13 rc-text-label clickable" v-on:click="showInspectionsLast2YearsWithMajorDefects">Total Inspections with unrepaired Majors</div>
                            <div class="rc-box-col-2 rc-text-centre rc-text-value clickable" v-on:click="showInspectionsLast2YearsWithMajorDefects">{{ statsLast2Years.reportedMajors() }}</div>
                          </Row>
                          <Row>
                            <div class="rc-box-col-13 rc-text-label clickable" v-on:click="showInspectionsLast2YearsWithMinorDefects">Total Inspections with unrepaired Minors</div>
                            <div class="rc-box-col-2 rc-text-centre rc-text-value clickable" v-on:click="showInspectionsLast2YearsWithMinorDefects">{{ statsLast2Years.reportedMinors() }}</div>
                          </Row>
                        </Box>
                      </Column>
                    </Row>
                  </Box>
                </Column>
              </Row>
              <SpacerRow />
              <ValueRow v-if="statsLast2Years.reportedMinors() + statsLast2Years.reportedMajors() > 0">
                You have inspections that have reported defects. These inspections should have their repair records updated when the vehicle is fixed. To do this, click on the <b>Total Inspections with unrepaired Defects {{ statsLast2Years.reportedDefects() }}</b> and press the Repair button on each inspection.
              </ValueRow>
              <ValueRow v-else>
                Congratulations, you have no inspections needed repair records updated.
              </ValueRow>

              <StringValueRow v-if="isLoadingLast2Years" value="Loading..." label="" />
              <SpacerRow v-else />
            </Box> <!-- container-->
          </Column>
          <Column :width="5" :showBorder="true">
            <Box>
              <TitleRowV2 :subtitle="true">Inspection Results Pie Chart</TitleRowV2>
              <Row>
                <div style="min-height: 305px; min-width: 305px;">
                  <div ref="last2years"></div>
                </div>
              </Row>
              <ValueRow v-if="statsLast2Years.reportedMinors() + statsLast2Years.reportedMajors() > 0">
                You need to record repairs against the {{ statsLast2Years.reportedDefects() }} inspections. <br> This pie chart should be Green.
              </ValueRow>
              <ValueRow v-else>
                Nice work, all your Inspections are Good!
              </ValueRow>
            </Box>
          </Column>
        </Row> <!-- row -->

      </Box>

      <div class="rc-container">
        <SpacerRow />
      </div>

      <Box :showBorder="true">
        <TitleRowV2> Trends </TitleRowV2>
        <Row>
          <Column>
            <Box>
              <TitleRowV2 :subtitle="true"> Last 15 Days </TitleRowV2>

              <div class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Date</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row">
                      <div v-for="(item, index) in statsList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre">
                        {{ item.isNew() ? item.createdDateV2().displayAs_MM_DD() : item.createdDateV2().day().previous().displayAs_MM_DD() }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="hasInspections" class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Inspections</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row ">
                      <div v-for="(item, index) in statsList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre ">
                        {{ item.inspections() }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasTimesheets" class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Hours of Service</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row">
                      <div v-for="(item, index) in statsList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre"> {{ item.timesheets() }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasCheckIns" class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Check-Ins</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row">
                      <div v-for="(item, index) in statsList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre"> {{ item.checkins() }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <StringValueRow v-if="isLoadingLast15Days" value="Loading..." label="" />
              <SpacerRow v-else />


              <TitleRowV2 :subtitle="true"> Last 15 Months </TitleRowV2>
              <div class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Date</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row">
                      <div v-for="(item, index) in statsMonthlyList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre"> 
                        {{ item.isNew() ? item.createdDateV2().month().name(true) : item.createdDateV2().month().previous().name(true) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasInspections" class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Inspections</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row">
                      <div v-for="(item, index) in statsMonthlyList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre"> {{ item.inspections() }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasTimesheets" class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Hours of Service</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row">
                      <div v-for="(item, index) in statsMonthlyList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre"> {{ item.timesheets() }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="hasCheckIns" class="rc-row rc-background-dark-top-bottom">
                <div class="rc-box-col-2 rc-text-label rc-text-right">Check-Ins</div>
                <div class="rc-box-col-13">
                  <div class="rc-container">
                    <div class="rc-row">
                      <div v-for="(item, index) in statsMonthlyList" v-bind:key="item.id()"
                        :class="Strings.isEven(index) ? 'rowEven rc-box-col-1' : 'rowOdd rc-box-col-1'"
                        class=" rc-text-value rc-text-centre"> {{ item.checkins() }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <StringValueRow v-if="isLoadingLast15Months" value="Loading..." label="" />
              <SpacerRow v-else />

            </Box>
          </Column>
        </Row>
      </Box>

    </Column>

  </Row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Catelog from "@/domain/session/CanadianEnglish.js";
import CompanyStats from "@/domain/model/company/CompanyStats.js";
import CompanyStatsMap from "@/domain/model/company/CompanyStatsMap.js";
import CompanyStatsListFilter from "@/domain/model/company/CompanyStatsListFilter.js";

import Timesheet from "@/domain/model/timesheet/Timesheet.js";
import TimesheetMap from "@/domain/model/timesheet/TimesheetMap.js";
import TimesheetListFilter from "@/domain/model/timesheet/TimesheetListFilter.js";

import FeatureMap from "@/domain/model/feature/FeatureMap.js";

import InspectionMap from "@/domain/model/inspection/InspectionMap.js";
import InspectionListFilter from "@/domain/model/inspection/InspectionListFilter.js";

import CheckInMap from "@/domain/model/checkin/CheckInMap.js";
import CheckInListFilter from "@/domain/model/checkin/CheckInListFilter.js";

import StringValueRow from '@/components/row/StringValueRow.vue';
import SpacerRow from '@/components/row/SpacerRow.vue';
import ErrorRow from '@/components/row/ErrorRow.vue';

import StringUtils from "@/utils/StringUtils.js";
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';

import Button from '@/portals/shared/library/button/Button.vue';
import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

//import Title from '@/portals/shared/library/title/Title.vue';
import TitleRowV2 from '@/portals/shared/library/title/TitleRow.vue';
import Value from '@/portals/shared/library/value/Value.vue';
import ValueRow from '@/portals/shared/library/value/ValueRow.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";
//import Loading from "@/portals/shared/library/loading/Loading.vue";
//import LabelValue from "@/portals/shared/library/labelvalue/LabelValue.vue";
// import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
//import Spacer from "@/portals/shared/library/spacer/Spacer.vue";

import HomeInspectionRow from "./HomeInspectionRow.vue";
import Plotly from 'plotly.js-dist-min';

export default {
  name: 'portal-operator-home-stats-row',
  components: {
    TitleRowV2, LoadingRow, ValueRow,
    StringValueRow, Value,
    SpacerRow,
    Button,
    ErrorRow,
    Box, Row, Column,
    HomeInspectionRow,
  },
  data() {
    return {
      error: null,
      loadingMessage: "",
      Keys: Catelog.KEYS.Home,
      Catelog: new Catelog(),
      isLoadingDetails: false,
      isLoadingDaily: false,
      isLoadingMonthly: false,
      stats: new CompanyStats(this.domain),
      statsMap: {},
      statsList: [],
      statsMonthly: new CompanyStats(this.domain),
      statsMonthlyMap: {},
      statsMonthlyList: [],
      Strings: StringUtils,
      pageSize: 10,
      pageNumber: 0,

      timesheetMap: new TimesheetMap(this.domain),
      timesheetList: [],
      isLoadingTimesheets: false,

      inspectionMap: new InspectionMap(this.domain),
      inspectionList: [],
      isLoadingInspections: false,
      inspectionPage: 0,
      inspectionTotal: 0,

      checkInMap: new CheckInMap(this.domain),
      checkInList: [],
      isLoadingCheckIns: false,

      isLoadingLast2Years: false,
      statsLast2Years: new CompanyStats(this.domain),

      isLoadingLast15Days: false,
      isLoadingLast15Months: false,
      odometerSum: "",
      isOdometerSumLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    odometerType: function () {
      var value = "";
      if (this.domain) {
        if (this.domain.session()) {
          var address = this.domain.session().company().find().address().find();
          if (address.isCanada()) {
            value = "KM";
          } else {
            value = "MI";
          }
        }
      }
      return value;
    },
    inspections: function () {
      var value = this.stats.inspections();
      value = StringUtils.toString(value);
      if (StringUtils.isEmpty(value)) {
        return "0";
      }
      return value;
    },
    vehicles: function () {
      var value = this.stats.vehicles();
      value = StringUtils.toString(value);
      if (StringUtils.isEmpty(value)) {
        return "0";
      }
      return value;
    },
    trailers: function () {
      var value = this.stats.trailers();
      value = StringUtils.toString(value);
      if (StringUtils.isEmpty(value)) {
        return "0";
      }
      return value;
    },
    timesheets: function () {
      var value = this.stats.timesheets();
      value = StringUtils.toString(value);
      if (StringUtils.isEmpty(value)) {
        return "0";
      }
      return value;
    },
    checkins: function () {
      var value = this.stats.checkins();
      value = StringUtils.toString(value);
      if (StringUtils.isEmpty(value)) {
        return "0";
      }
      return value;
    },
    users: function () {
      var value = this.stats.users();
      value = StringUtils.toString(value);
      if (StringUtils.isEmpty(value)) {
        return "0";
      }
      return value;
    },
    hasUserCompliance: function () {
      var options = this.features;
      return options.allowsCompliance();
    },
    hasCheckIns: function () {
      var options = this.features;
      return options.allowsCheckIns();
    },
    hasInspections: function () {
      var options = this.features;
      return options.allowsInspections();
    },
    hasTimesheets: function () {
      var options = this.features;
      return options.allowsTimesheets();
    },
    features: function () {
      var options = new FeatureMap(this.domain, {});
      if (!this.domain) {
        return options;
      }
      var session = this.domain.session();
      if (!session) {
        return options;
      }
      var company = session.company();
      if (!company) {
        return options;
      }
      options = company.features().find();
      return options
    },
    sectionWidth: function () {
      return "rc-box-col-3";
    },
    monthlyIncomplete: function () {
      var value = this.statsMonthly.incomplete();
      if (value < 0) {
        value = 0;
      }
      return value;
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    this.stats = new CompanyStats(this.domain, {});
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
      'AuthStorage_updateCompany',
      'InspectionsStore_clear',
      'addListenerAction',
      'removeListenerAction',
      'updateDomain',
    ]),
    start: function () {
      this.isLoading = false;
      this.message = null;
      this.loadingMessage = "";
      this.load();
    },
    callback: function (msg) {
      this.loadingMessage = msg;
    },
    load: function () {
      this.isLoadingDetails = true;
      this.isLoadingDaily = true;
      this.isLoadingMonthly = true;
      this.stats = new CompanyStats(this.domain, {});
      this.statsList = [];
      this.statsMonthlyList = [];
      this.statsMonthly = new CompanyStats(this.domain, {});

      this.timesheetMap = new TimesheetMap(this.domain);
      this.isLoadingTimesheets = false;
      this.loadTimesheetsByFilter();

      this.inspectionMap = new InspectionMap(this.domain);
      this.isLoadingInspections = false;
      this.loadInspectionsByFilter();

      this.checkInMap = new CheckInMap(this.domain);
      this.isLoadingCheckIns = false;
      this.loadCheckInsByFilter();

      this.loadDetails();
      this.loadMonthlyDetails();
      this.loadLast14Days();
      this.loadLast14Months();
      this.loadLast2Years();
      this.loadVehiclesOdometerSum();
    },

    loadDetails: function () {
      if (!this.domain) {
        return;
      }
      var request = this.domain.events().company().statsDetails();
      request.send(this.loadDetailsListener);
    },
    loadDetailsListener: function (event) {
      var payload = event.payload();
      if (event.error()) {
        this.error = event.error();
      } else {
        if (payload) {
          this.stats = new CompanyStats(this.domain, payload.valuesFor(CompanyStats.MODEL_NAME));
        }
      }
      this.isLoadingDaily = false;
    },

    //Load monthly details
    loadMonthlyDetails: function () {
      if (!this.domain) {
        return;
      }
      this.isLoadingMonthly = true;
      var request = this.domain.events().company().statsDetails('thisMonth');
      request.send(this.loadMonthlyDetailsListener);
    },
    loadMonthlyDetailsListener: function (event) {
      var payload = event.payload();
      if (event.error()) {
        this.error = event.error();
      } else {
        if (payload) {
          this.statsMonthly = new CompanyStats(this.domain, payload.valuesFor(CompanyStats.MODEL_NAME));
        }
      }
      this.isLoadingMonthly = false;
    },

    // Last 14 Days
    loadLast14Days: function () {
      if (!this.domain) {
        return;
      }
      this.isLoadingLast15Days = true;
      var filter = new CompanyStatsListFilter();
      filter
        .withPagination(14, this.pageNumber)
        .withCompany(this.domain.session().company().id())
        .withTypeDaily()
        .done();
      var request = this.domain.events().company().statsList(filter);
      request.send(this.loadLast14DaysListener);
    },

    loadLast14DaysListener: function (event) {
      var payload = event.payload();
      if (event.error()) {
        this.error = event.error();
      } else {
        if (payload) {
          this.statsMap = new CompanyStatsMap(this.domain, payload.valuesFor(CompanyStatsMap.MODEL_NAME));
          this.statsList = this.statsMap.sortByCreatedDateDesc();
        }
      }
      this.isLoadingLast15Days = false;
    },

    // Last 14 Months
    loadLast14Months: function () {
      if (!this.domain) {
        return;
      }
      this.isLoadingLast15Months = true;
      var filter = new CompanyStatsListFilter();
      filter
        .withPagination(14, this.pageNumber)
        .withCompany(this.domain.session().company().id())
        .withTypeMonthly()
        .done();
      var request = this.domain.events().company().statsList(filter);
      request.send(this.loadLast14MonthsListener);
    },

    loadLast14MonthsListener: function (event) {
      var payload = event.payload();
      if (event.error()) {
        this.error = event.error();
      } else {
        if (payload) {
          this.statsMonthlyMap = new CompanyStatsMap(this.domain, payload.valuesFor(CompanyStatsMap.MODEL_NAME));
          this.statsMonthlyList = this.statsMonthlyMap.sortByCreatedDateDesc();
        }
      }
      this.isLoadingLast15Months = false;
    },

    // Last 2 Years
    loadLast2Years: function () {
      this.statsLast2Years = new CompanyStats(this.domain);
      if (!this.domain) {
        return;
      }
      this.isLoadingLast2Years = true;
      var request = this.domain.events().company().statsDetails('last2Years');
      request.send(this.loadLast2YearsListener);
    },
    loadLast2YearsListener: function (event) {
      var payload = event.payload();
      if (event.error()) {
        this.error = event.error();
      } else {
        if (payload) {
          this.statsLast2Years = new CompanyStats(this.domain, payload.valuesFor(CompanyStats.MODEL_NAME));
        }
      }
      
      this.isLoadingLast2Years = false;

      var reportedMinors = this.statsLast2Years.reportedMinors();
      var reportedMajors = this.statsLast2Years.reportedMajors();
      var allInspection = this.statsLast2Years.inspections() - reportedMajors - reportedMinors;
      var data = [{
          values: [allInspection, reportedMinors, reportedMajors],
          labels: ['Good', 'Minors', 'Majors'],
          marker: {
            colors: [
              '#009436',
              '#F39F42',
              'rgb(215, 11, 11)',
            ]
          },
          type: 'pie',
          textinfo: "label+percent",
          textposition: "outside",
          automargin: true
        }];

        var layout = {
          height: 250,
          width: 250,
          margin: {"t": 0, "b": 0, "l": 0, "r": 0},
          showlegend: false,
        };
      var elm = this.$refs.last2years;
      Plotly.newPlot(elm, data, layout);
    },

    loadVehiclesOdometerSum: function () {
      this.isOdometerSumLoading = true;
      this.odometerSum = "";
      if (!this.domain) {
        return;
      }
      var request = this.domain.events().vehicles().odometerSum();
      request.send(this.loadVehicleOdometerSumListener);
    },
    loadVehicleOdometerSumListener: function (event) {
      var payload = event.payload();
      if (payload) {
        var sum = payload.getString("odometerSum");
        this.odometerSum = ContentUtils.sumDisplay(sum);
      }
      this.isOdometerSumLoading = false;
      this.isLoadingDetails = false;
    },

    refreshStats: function () {
      this.load();
    },

    insightsLabel: function () {
      if (!this.domain) {
        return "";
      }
      return StringUtils.format("Insights for {0}", [this.domain.session().time().now().month().name()]);
    },

    showInspectionsWithDefects: function () {
      var params = {
        status: 'any',
        time: 'thisMonth'
      }
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      })
    },

    showInspections: function (time) {
      if (!time) {
        time = 'thisMonth';
      }
      var params = {
        time: time
      };
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      });
    },
    showInspectionsWithMinorDefects: function () {
      var params = {
        status: 'minor',
        time: 'thisMonth'
      }
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      });
    },
    showInspectionsWithMajorDefects: function () {
      var params = {
        status: 'major',
        time: 'thisMonth'
      }
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      });
    },

    showInspectionsLast2YearsWithDefects: function () {
      var params = {
        status: 'any',
        time: 'last2Years'
      }
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      })
    },

    showInspectionsLast2Years: function (time) {
      if (!time) {
        time = 'last2Years';
      }
      var params = {
        time: time
      };
      var format = StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]);
      this.$router.push({
        path: format,
        query: params,
      });
    },
    showInspectionsLast2YearsWithMinorDefects: function () {
      var params = {
        status: 'minor',
        time: 'last2Years'
      }
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      });
    },
    showInspectionsLast2YearsWithMajorDefects: function () {
      var params = {
        status: 'major',
        time: 'last2Years'
      }
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.INSPECTION.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      });
    },

    showVehiclesAll: function () {
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLES,
      });
    },

    showVehicles: function () {
      var params = {
        type: 'vehicle'
      };
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLES,
        query: params,
      });
    },
    showTrailers: function () {
      var params = {
        type: 'trailer'
      };
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLES,
        query: params,
      });
    },
    showUsers: function (value) {
      var params = {
        state: value
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.LIST,
        query: params,
      });
    },
    showUsersInvited: function () {
      var params = {
        state: 'invited'
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.LIST,
        query: params,
      });
    },
    showCheckIns: function (time) {
      if (!time) {
        time = 'thisMonth';
      }
      var params = {
        time: time,
      };
      this.$router.push({
        name: ConstUtils.ROUTES.CHECKIN.LIST,
        query: params,
      });
    },
    showHours: function (time) {
      if (!time) {
        time = 'thisMonth';
      }
      var query = {
        time: time,
      };
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.TIMESHEET.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query,
      });
    },
    showHoursIncomplete: function () {
      var params = {
        status: 'incomplete',
        time: 'thisMonth',
      };
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.TIMESHEET.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      });
    },

    showHoursInProgress: function () {
      var params = {
        status: 'inprogress',
        time: 'thisMonth',
      };
      this.$router.push({
        path: StringUtils.format(ConstUtils.ROUTES.TIMESHEET.LIST_PATH, [this.domain.session().company().idEncoded()]),
        query: params,
      });
    },

    showHoursMissing: function () {
      this.$router.push({
        name: ConstUtils.ROUTES.COMPLIANCE.USERS,
      });
    },

    showHourDetails: function (timesheet) {
      var params = {
        id: timesheet.id(),
      };
      this.$router.push({
        name: ConstUtils.ROUTES.TIMESHEET.DETAILS,
        params: params,
      });
    },

    showInspectionDetails: function (inspection) {
      var params = {
        id: inspection.id(),
      };
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION.DETAILS,
        params: params,
      });
    },

    showCheckInDetails: function (checkIn) {
      var params = {
        id: checkIn.id(),
      };
      this.$router.push({
        name: ConstUtils.ROUTES.CHECKIN.DETAILS,
        params: params,
      });
    },

    showVehicleDetails: function (vehicle) {
      var params = {
        id: vehicle.id(),
      };
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLE.DETAILS,
        params: params,
      });
    },
    showEmployeeDetails: function (employee) {
      var params = {
        id: employee.id(),
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.DETAILS,
        params: params,
      });
    },

    loadTimesheetsByFilter: function () {
      this.timesheetMap = new TimesheetMap(this.domain);
      this.timesheetList = [];
      if (!this.hasTimesheets) {
        return;
      }
      this.isLoadingTimesheets = true;

      var filter = new TimesheetListFilter();
      filter
        .withDateRange('today')
        .withPagination(20, this.pageNumber)
        .withCompany(this.domain.session().company().id())
        .done();

      var event = this.domain
        .events()
        .timesheets().list(filter);
      event.send(this.timesheetListListener);
    },

    timesheetListListener: function (event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(TimesheetMap.MODEL_NAME);
        if (map) {
          this.timesheetMap = new TimesheetMap(this.domain, map.data);
          this.timesheetList = this.timesheetMap.sort();
        }
      }
      this.isLoadingTimesheets = false;
    },

    loadInspectionsByFilter: function () {
      this.inspectionMap = new InspectionMap(this.domain);
      this.inspectionList = [];
      if (!this.hasInspections) {
        return;
      }
      this.InspectionsStore_clear();
      this.isLoadingInspections = true;
      var filter = new InspectionListFilter();
      filter
        .withDateRange('today')
        .withPagination(this.pageSize, this.inspectionPage)
        .done();

      filter
        .withCompany(this.domain.session().company().id())
        .done();

      var event = this.domain
        .events()
        .inspections().list(filter);
      event.with("populate", true);
      event.send(this.inspectionsListListener);
    },

    inspectionsListListener: function (event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(InspectionMap.MODEL_NAME);
        if (map) {
          this.inspectionMap = new InspectionMap(this.domain, map);
          this.inspectionList = this.inspectionMap.sort();
          this.inspectionTotal = payload.count();
        }
      }
      this.isLoadingInspections = false;
    },

    loadCheckInsByFilter: function () {
      this.checkInMap = new CheckInMap(this.domain);
      this.checkInList = [];
      if (!this.hasCheckIns) {
        return;
      }
      this.isLoadingCheckIns = true;
      var filter = new CheckInListFilter(this.domain);
      filter
        .withDateRange('today')
        .withPagination(20, this.pageNumber)
        .withCompany(this.domain.session().company().id())
        .done();
      var event = this.domain.events().checkins().list(filter);
      event.with("populate", false);
      event.send(this.checkInsListListener);
    },

    checkInsListListener: function (event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(CheckInMap.MODEL_NAME);
        this.checkInMap = new CheckInMap(this.domain, map);
        this.checkInList = this.checkInMap.sort();
      }
      this.isLoadingCheckIns = false;
    },

    defectTypeColour: function (result) {
      if (result == Timesheet.Values.Status.COMPLETED_OFF_DAY) {
        return 'rc-input-na';
      }
      if (result == 'None' || result == Timesheet.Values.Status.COMPLETE) {
        return 'rc-input-good';
      }
      if (result == 'Minor' || result == Timesheet.Values.Status.INCOMPLETE) {
        return 'rc-input-minor';
      }
      if (result == 'Major' || result == Timesheet.Values.Status.MISSING) {
        return 'rc-input-major';
      }
      if (result == Timesheet.Values.Status.INPROGRESS) {
        return 'rc-input-inprogress';
      }

      return 'rc-input-good';
    },
    previousPage: function (itemName) {
      if (itemName === 'inspections') {
        if (this.canPreviousPage(this.inspectionPage)) {
          this.inspectionPage--;
          this.loadInspectionsByFilter();
        }
      }
    },
    nextPage: function (itemName) {
      if (itemName === 'inspections') {
        if (this.canNextPage(this.inspectionTotal, this.inspectionPage)) {
          this.inspectionPage++;
          this.loadInspectionsByFilter();
        }
      }
    },

    canPreviousPage: function (pageNumber) {
      return pageNumber > 0;
    },
    canNextPage: function (total, pageNumber) {
      return total > (this.pageSize * pageNumber) + this.pageSize
    },

  },
}
</script>