
import RcObject from "@/domain/common/RcObject.js";
import Inspection from "./Inspection.js";

import Employee from "@/domain/model/employee/Employee.js";
import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";

import Vehicle from "@/domain/model/vehicle/Vehicle.js";
import Company from "@/domain/model/company/Company.js";

export default class InspectionListFilter extends RcObject {
  
  static Fields = {
    CREATED_DATE_RANGE: 'createDateRange',
    DEFECT_TYPE: Inspection.Fields.DEFECT_TYPE,
    PAGINATION: 'pagination',
    SORT_BY: "sortBy",
    EMPLOYEE: Employee.MODEL_NAME,
    EMPLOYEES: EmployeeMap.MODEL_NAME,
    VEHICLE: Vehicle.MODEL_NAME,
    COMPANY: Company.MODEL_NAME,
  }
  
  constructor() {
    super();
  }
  
  withSortBy(sortBy) {
    let direction = "asc";
    
    let newValue = sortBy.replace("Az", "");
    if (newValue.length < sortBy.length) {
      direction = "asc";
      this.put(InspectionListFilter.Fields.SORT_BY, { 'value': newValue, 'dir': direction });
    }
    
    newValue = sortBy.replace("Za", "");
    if (newValue.length < sortBy.length) {
      direction = "des";
      this.put(InspectionListFilter.Fields.SORT_BY, { 'value': newValue, 'dir': direction });
    }
    return this;
  }

  withCompany(companyId) {
    this.put(InspectionListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withDateRange(range) {
    this.put(InspectionListFilter.Fields.CREATED_DATE_RANGE, range);
    return this;
  }
  
  withDefectType(values) {
    this.put(InspectionListFilter.Fields.DEFECT_TYPE, values);
    return this;
  }
  
  withPagination(size, number) {
    this.put(InspectionListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withEmployee(employee) {
    if (!employee) {
      return this;
    }
    this.put(InspectionListFilter.Fields.EMPLOYEE, employee);
    return this;
  }
  
  withVehicle(vehicle) {
    if (!vehicle) {
      return this;
    }
    this.put(InspectionListFilter.Fields.VEHICLE, vehicle);
    return this;
  }
  
  withEmployees(employees) {
    if (!employees) {
      return this;
    }
    this.put(InspectionListFilter.Fields.EMPLOYEES, employees.data);
    return this;
  }
  
  done() {
    //
  }
}